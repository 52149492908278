"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var StateDashboard_1 = require("../../../../../modules/shapediver-state-dashboard/StateDashboard");
var ICameraHandler_1 = require("../../../interfaces/ICameraHandler");
exports.CameraHooks = {
    "autoAdjust": function (value) {
        var _this = this;
        if (!this._globalUtils.typeCheck(value, "boolean", this._handlers.threeDManager.warn, "CameraHandler.Hook->autoAdjust"))
            return false;
        if (value && !this._autoAdjustEventListenerToken)
            this._autoAdjustEventListenerToken = this._handlers.threeDManager.viewerApi.scene.addEventListener(this._handlers.threeDManager.viewerApi.scene.EVENTTYPE.SUBSCENE_PUBLISHED, function () {
                // we call zoomExtents only if scene is visible, this allows us to avoid the call to zoomExtents for the very initial update of the scene after loading
                if (_this._settings.getSetting("autoAdjust") && _this._handlers.threeDManager.getSetting("show"))
                    _this.zoomExtents({ default: true });
            });
        return true;
    },
    "cameraMovementDuration": function (value) {
        return true;
    },
    "enableCameraControls": function (value) {
        if (!this._globalUtils.typeCheck(value, "boolean", this._handlers.threeDManager.warn, "CameraHandler.Hook->enableCameraControls"))
            return false;
        this._cameraControls.enabled = value;
        return true;
    },
    "revertAtMouseUp": function (value) {
        var _this = this;
        if (!this._globalUtils.typeCheck(value, "boolean", this._handlers.threeDManager.warn, "CameraHandler.Hook->revertAtMouseUp"))
            return false;
        var domElement = this._handlers.renderingHandler.getDomElement();
        if (!this._revertAtMouseUpEventListenerToken)
            this._revertAtMouseUpEventListenerToken = domElement.addEventListener("mouseup", function () {
                if (_this._settings.getSetting("revertAtMouseUp"))
                    _this.resetPositionAndTarget({
                        duration: _this._settings.getSetting("revertAtMouseUpDuration")
                    });
            }, this._detect_it.passiveEvents ? { capture: false, passive: true } : false);
        if (!this._revertAtMouseOutEventListenerToken)
            this._revertAtMouseOutEventListenerToken = domElement.addEventListener("mouseout", function (e) {
                if (e.which && _this._settings.getSetting("revertAtMouseUp"))
                    _this.resetPositionAndTarget({
                        duration: _this._settings.getSetting("revertAtMouseUpDuration")
                    });
            }, this._detect_it.passiveEvents ? { capture: false, passive: true } : false);
        if (!this._revertAtTouchEndEventListenerToken)
            this._revertAtTouchEndEventListenerToken = domElement.addEventListener("touchend", function () {
                if (_this._settings.getSetting("revertAtMouseUp"))
                    _this.resetPositionAndTarget({
                        duration: _this._settings.getSetting("revertAtMouseUpDuration")
                    });
            }, this._detect_it.passiveEvents ? { capture: false, passive: true } : false);
        if (!this._revertAtMouseWheelEventListenerToken) {
            var zoomResizeTimer_1 = 0;
            var mousewheelevt = /Firefox/i.test(navigator.userAgent) ? "DOMMouseScroll" : "mousewheel"; //FF doesn't recognize mousewheel as of FF3.x
            this._revertAtMouseWheelEventListenerToken = domElement.addEventListener(mousewheelevt, function () {
                clearTimeout(zoomResizeTimer_1);
                if (_this._settings.getSetting("revertAtMouseUp")) {
                    zoomResizeTimer_1 = setTimeout(function () {
                        _this.resetPositionAndTarget({
                            duration: _this._settings.getSetting("revertAtMouseUpDuration")
                        });
                    }, 300);
                }
            }, this._detect_it.passiveEvents ? { capture: false, passive: true } : false);
        }
        return true;
    },
    "revertAtMouseUpDuration": function (value) {
        return true;
    },
    "zoomExtentsFactor": function (value) {
        return true;
    },
    "defaults": function (value) {
        return this._settings.updateSetting('defaults.perspective', value.perspective) && this._settings.updateSetting('defaults.orthographic', value.orthographic);
    },
    "defaults.perspective.position": function (value) {
        return this._settings.updateSetting('cameraTypes.perspective.default.position', value);
    },
    "defaults.perspective.target": function (value) {
        return this._settings.updateSetting('cameraTypes.perspective.default.target', value);
    },
    "defaults.perspective": function (value) {
        // change old default position to new default position
        if (value.position.x === 5 && value.position.y === 5 && value.position.z === 5 &&
            value.target.x === 0 && value.target.y === 0 && value.target.z === 0)
            value.position = { x: 0, y: 0, z: 0 };
        return this._settings.updateSetting('cameraTypes.perspective.default', value);
    },
    "defaults.orthographic.position": function (value) {
        return this._settings.updateSetting('cameraTypes.orthographic.default.position', value);
    },
    "defaults.orthographic.target": function (value) {
        return this._settings.updateSetting('cameraTypes.orthographic.default.target', value);
    },
    "defaults.orthographic": function (value) {
        // change old default position to new default position
        if (value.position.x === 5 && value.position.y === 5 && value.position.z === 5 &&
            value.target.x === 0 && value.target.y === 0 && value.target.z === 0)
            value.position = { x: 0, y: 0, z: 0 };
        return this._settings.updateSetting('cameraTypes.orthographic.default', value);
    },
    "fov": function (value) {
        return this._settings.updateSetting('cameraTypes.perspective.fov', value);
    },
    "type": function (value) {
        return this._settings.updateSetting('cameraTypes.active', value);
    },
    "autoRotationSpeed": function (value) {
        return this._settings.updateSetting('controls.orbit.autoRotationSpeed', value);
    },
    "damping": function (value) {
        return this._settings.updateSetting('controls.orbit.damping', value);
    },
    "enableAutoRotation": function (value) {
        return this._settings.updateSetting('controls.orbit.enableAutoRotation', value);
    },
    "enableKeyPan": function (value) {
        return this._settings.updateSetting('controls.orbit.enableKeyPan', value);
    },
    "enablePan": function (value) {
        return this._settings.updateSetting('controls.orbit.enablePan', value);
    },
    "enableRotation": function (value) {
        return this._settings.updateSetting('controls.orbit.enableRotation', value);
    },
    "enableZoom": function (value) {
        return this._settings.updateSetting('controls.orbit.enableZoom', value);
    },
    "keyPanSpeed": function (value) {
        return this._settings.updateSetting('controls.orbit.keyPanSpeed', value);
    },
    "restrictions.position.cube.min": function (value) {
        return this._settings.updateSetting('controls.orbit.restrictions.position.cube.min', value);
    },
    "restrictions.position.cube.max": function (value) {
        return this._settings.updateSetting('controls.orbit.restrictions.position.cube.max', value);
    },
    "restrictions.position.cube": function (value) {
        return this._settings.updateSetting('controls.orbit.restrictions.position.cube', value);
    },
    "restrictions.position.sphere.center": function (value) {
        return this._settings.updateSetting('controls.orbit.restrictions.position.sphere.center', value);
    },
    "restrictions.position.sphere.radius": function (value) {
        return this._settings.updateSetting('controls.orbit.restrictions.position.sphere.radius', value);
    },
    "restrictions.position.sphere": function (value) {
        return this._settings.updateSetting('controls.orbit.restrictions.position.sphere', value);
    },
    "restrictions.target.cube.min": function (value) {
        return this._settings.updateSetting('controls.orbit.restrictions.target.cube.min', value);
    },
    "restrictions.target.cube.max": function (value) {
        return this._settings.updateSetting('controls.orbit.restrictions.target.cube.max', value);
    },
    "restrictions.target.cube": function (value) {
        return this._settings.updateSetting('controls.orbit.restrictions.target.cube', value);
    },
    "restrictions.target.sphere.center": function (value) {
        return this._settings.updateSetting('controls.orbit.restrictions.target.sphere.center', value);
    },
    "restrictions.target.sphere.radius": function (value) {
        return this._settings.updateSetting('controls.orbit.restrictions.target.sphere.radius', value);
    },
    "restrictions.target.sphere": function (value) {
        return this._settings.updateSetting('controls.orbit.restrictions.target.sphere', value);
    },
    "restrictions.rotation.minPolarAngle": function (value) {
        return this._settings.updateSetting('controls.orbit.restrictions.rotation.minPolarAngle', value);
    },
    "restrictions.rotation.maxPolarAngle": function (value) {
        return this._settings.updateSetting('controls.orbit.restrictions.rotation.maxPolarAngle', value);
    },
    "restrictions.rotation.minAzimuthAngle": function (value) {
        return this._settings.updateSetting('controls.orbit.restrictions.rotation.minAzimuthAngle', value);
    },
    "restrictions.rotation.maxAzimuthAngle": function (value) {
        return this._settings.updateSetting('controls.orbit.restrictions.rotation.maxAzimuthAngle', value);
    },
    "restrictions.rotation": function (value) {
        return this._settings.updateSetting('controls.orbit.restrictions.rotation', value);
    },
    "restrictions.zoom.minDistance": function (value) {
        return this._settings.updateSetting('controls.orbit.restrictions.zoom.minDistance', value);
    },
    "restrictions.zoom.maxDistance": function (value) {
        return this._settings.updateSetting('controls.orbit.restrictions.zoom.maxDistance', value);
    },
    "restrictions.zoom": function (value) {
        return this._settings.updateSetting('controls.orbit.restrictions.zoom', value);
    },
    "rotationSpeed": function (value) {
        return this._settings.updateSetting('controls.orbit.rotationSpeed', value);
    },
    "panSpeed": function (value) {
        return this._settings.updateSetting('controls.orbit.panSpeed', value);
    },
    "zoomSpeed": function (value) {
        return this._settings.updateSetting('controls.orbit.zoomSpeed', value);
    },
};
exports.CameraNotifiers = {
    "zoomExtentsFactor": function () {
        var _a;
        var stateDashboard = StateDashboard_1.StateDashboardLibrary.getInstance(this._handlers.threeDManager.viewerApi.getSetting('viewerRuntimeId'));
        if (!stateDashboard.firstTimeVisible.resolved || !stateDashboard.settingsLoaded.resolved)
            return;
        if (!((_a = stateDashboard.getCustomState(this._handlers.threeDManager.api.getViewportRuntimeId() + '_settingsRestored')) === null || _a === void 0 ? void 0 : _a.resolved))
            return;
        var cd = this.getPositionAndTarget();
        if (this._settings.getSetting('cameraTypes.active') === ICameraHandler_1.CameraType.PERSPECTIVE && (cd.position.x === 0 && cd.position.y === 0 && cd.position.z === 0 && cd.target.x === 0 && cd.target.y === 0 && cd.target.z === 0))
            return;
        if (this._active)
            this.zoomExtents();
    },
    "cameraTypes.active": function () {
        return this._settings.forceSetting('type', this._settings.getSetting('cameraTypes.active'));
    },
    "cameraTypes.perspective.default.position": function () {
        return this._settings.forceSetting('defaults.perspective.position', this._settings.getSetting('cameraTypes.perspective.default.position'));
    },
    "cameraTypes.perspective.default.target": function () {
        return this._settings.forceSetting('defaults.perspective.target', this._settings.getSetting('cameraTypes.perspective.default.target'));
    },
    "cameraTypes.perspective.default": function () {
        return this._settings.forceSetting('defaults.perspective', this._settings.getSetting('cameraTypes.perspective.default'));
    },
    "cameraTypes.orthographic.default.position": function () {
        return this._settings.forceSetting('defaults.orthographic.position', this._settings.getSetting('cameraTypes.orthographic.default.position'));
    },
    "cameraTypes.orthographic.default.target": function () {
        return this._settings.forceSetting('defaults.orthographic.target', this._settings.getSetting('cameraTypes.orthographic.default.target'));
    },
    "cameraTypes.orthographic.default": function () {
        return this._settings.forceSetting('defaults.orthographic', this._settings.getSetting('cameraTypes.orthographic.default'));
    },
    "cameraTypes.perspective.fov": function () {
        return this._settings.forceSetting('fov', this._settings.getSetting('cameraTypes.perspective.fov'));
    },
    "controls.orbit.autoRotationSpeed": function () {
        return this._settings.forceSetting('autoRotationSpeed', this._settings.getSetting('controls.orbit.autoRotationSpeed'));
    },
    "controls.orbit.damping": function () {
        return this._settings.forceSetting('damping', this._settings.getSetting('controls.orbit.damping'));
    },
    "controls.orbit.controls.orbit.enableAutoRotation": function () {
        return this._settings.forceSetting('enableAutoRotation', this._settings.getSetting('controls.orbit.enableAutoRotation'));
    },
    "controls.orbit.enableKeyPan": function () {
        return this._settings.forceSetting('enableKeyPan', this._settings.getSetting('controls.orbit.enableKeyPan'));
    },
    "controls.orbit.enablePan": function () {
        return this._settings.forceSetting('enablePan', this._settings.getSetting('controls.orbit.enablePan'));
    },
    "controls.orbit.enableRotation": function () {
        return this._settings.forceSetting('enableRotation', this._settings.getSetting('controls.orbit.enableRotation'));
    },
    "controls.orbit.enableZoom": function () {
        return this._settings.forceSetting('enableZoom', this._settings.getSetting('controls.orbit.enableZoom'));
    },
    "controls.orbit.keyPanSpeed": function () {
        return this._settings.forceSetting('keyPanSpeed', this._settings.getSetting('controls.orbit.keyPanSpeed'));
    },
    "controls.orbit.restrictions.position.cube.min": function () {
        return this._settings.forceSetting('restrictions.position.cube.min', this._settings.getSetting('controls.orbit.restrictions.position.cube.min'));
    },
    "controls.orbit.restrictions.position.cube.max": function () {
        return this._settings.forceSetting('restrictions.position.cube.max', this._settings.getSetting('controls.orbit.restrictions.position.cube.max'));
    },
    "controls.orbit.restrictions.position.cube": function () {
        return this._settings.forceSetting('restrictions.position.cube', this._settings.getSetting('controls.orbit.restrictions.position.cube'));
    },
    "controls.orbit.restrictions.position.sphere.center": function () {
        return this._settings.forceSetting('restrictions.position.sphere.center', this._settings.getSetting('controls.orbit.restrictions.position.sphere.center'));
    },
    "controls.orbit.restrictions.position.sphere.radius": function () {
        return this._settings.forceSetting('restrictions.position.sphere.radius', this._settings.getSetting('controls.orbit.restrictions.position.sphere.radius'));
    },
    "controls.orbit.restrictions.position.sphere": function () {
        return this._settings.forceSetting('restrictions.position.sphere', this._settings.getSetting('controls.orbit.restrictions.position.sphere'));
    },
    "controls.orbit.restrictions.target.cube.min": function () {
        return this._settings.forceSetting('restrictions.target.cube.min', this._settings.getSetting('controls.orbit.restrictions.target.cube.min'));
    },
    "controls.orbit.restrictions.target.cube.max": function () {
        return this._settings.forceSetting('restrictions.target.cube.max', this._settings.getSetting('controls.orbit.restrictions.target.cube.max'));
    },
    "controls.orbit.restrictions.target.cube": function () {
        return this._settings.forceSetting('restrictions.target.cube', this._settings.getSetting('controls.orbit.restrictions.target.cube'));
    },
    "controls.orbit.restrictions.target.sphere.center": function () {
        return this._settings.forceSetting('restrictions.target.sphere.center', this._settings.getSetting('controls.orbit.restrictions.target.sphere.center'));
    },
    "controls.orbit.restrictions.target.sphere.radius": function () {
        return this._settings.forceSetting('restrictions.target.sphere.radius', this._settings.getSetting('controls.orbit.restrictions.target.sphere.radius'));
    },
    "controls.orbit.restrictions.target.sphere": function () {
        return this._settings.forceSetting('restrictions.target.sphere', this._settings.getSetting('controls.orbit..restrictions.target.sphere'));
    },
    "controls.orbit.restrictions.rotation.minPolarAngle": function () {
        return this._settings.forceSetting('restrictions.rotation.minPolarAngle', this._settings.getSetting('controls.orbit.restrictions.rotation.minPolarAngle'));
    },
    "controls.orbit.restrictions.rotation.maxPolarAngle": function () {
        return this._settings.forceSetting('restrictions.rotation.maxPolarAngle', this._settings.getSetting('controls.orbit.restrictions.rotation.maxPolarAngle'));
    },
    "controls.orbit.restrictions.rotation.minAzimuthAngle": function () {
        return this._settings.forceSetting('restrictions.rotation.minAzimuthAngle', this._settings.getSetting('controls.orbit.restrictions.rotation.minAzimuthAngle'));
    },
    "controls.orbit.restrictions.rotation.maxAzimuthAngle": function () {
        return this._settings.forceSetting('restrictions.rotation.maxAzimuthAngle', this._settings.getSetting('controls.orbit.restrictions.rotation.maxAzimuthAngle'));
    },
    "controls.orbit.restrictions.rotation": function () {
        return this._settings.forceSetting('restrictions.rotation', this._settings.getSetting('controls.orbit.restrictions.rotation'));
    },
    "controls.orbit.restrictions.zoom.minDistance": function () {
        return this._settings.forceSetting('restrictions.zoom.minDistance', this._settings.getSetting('controls.orbit.restrictions.zoom.minDistance'));
    },
    "controls.orbit.restrictions.zoom.maxDistance": function () {
        return this._settings.forceSetting('restrictions.zoom.maxDistance', this._settings.getSetting('controls.orbit.restrictions.zoom.maxDistance'));
    },
    "controls.orbit.restrictions.zoom": function () {
        return this._settings.forceSetting('restrictions.zoom', this._settings.getSetting('controls.orbit.restrictions.zoom'));
    },
    "controls.orbit.rotationSpeed": function () {
        return this._settings.forceSetting('rotationSpeed', this._settings.getSetting('controls.orbit.rotationSpeed'));
    },
    "controls.orbit.panSpeed": function () {
        return this._settings.forceSetting('panSpeed', this._settings.getSetting('controls.orbit.panSpeed'));
    },
    "controls.orbit.zoomSpeed": function () {
        return this._settings.forceSetting('zoomSpeed', this._settings.getSetting('controls.orbit.zoomSpeed'));
    },
};
