/*
 * @author zz85 / https://github.com/zz85
 * @author mrdoob / http://mrdoob.com
 * Running this will allow you to drag three.js objects around the screen.
 */

/* eslint no-console: "off" */

const THREE = require('../../externals/three'),
      DETECT_IT = (require('detect-it')).default;

THREE.DragControls = function ( _objects, _camera, _domElement ) {

  if ( _objects instanceof THREE.Camera ) {

    console.warn( 'THREE.DragControls: Constructor now expects ( objects, camera, domElement )' );
    var temp = _objects; _objects = _camera; _camera = temp;

  }

  var _plane = new THREE.Plane();
  var _raycaster = new THREE.Raycaster();

  var _mouse = new THREE.Vector2();
  var _offset = new THREE.Vector3();
  var _intersection = new THREE.Vector3();

  // currently selected (dragged) object, currently hovered object
  var _selected = null, _hovered = null;

  // id of the touch we are following, see https://developer.mozilla.org/en-US/docs/Web/API/Touch
  var _touchId = null;

  //

  var scope = this;

  function activate() {

    // if passive events are supported by the browser, add passive option
    let useCapture = false;
    if (DETECT_IT.passiveEvents === true) {
      useCapture = {
        capture: false,
        passive: false
      };
    }

    _domElement.addEventListener( 'mousemove', onDocumentMouseMove, useCapture );
    _domElement.addEventListener( 'mousedown', onDocumentMouseDown, useCapture );
    _domElement.addEventListener( 'mouseup', onDocumentMouseCancel, useCapture );
    _domElement.addEventListener( 'mouseleave', onDocumentMouseCancel, useCapture );
    _domElement.addEventListener( 'touchmove', onDocumentTouchMove, useCapture );
    _domElement.addEventListener( 'touchstart', onDocumentTouchStart, useCapture );
    _domElement.addEventListener( 'touchend', onDocumentTouchEnd, useCapture );
  }

  function deactivate() {

    _domElement.removeEventListener( 'mousemove', onDocumentMouseMove, false );
    _domElement.removeEventListener( 'mousedown', onDocumentMouseDown, false );
    _domElement.removeEventListener( 'mouseup', onDocumentMouseCancel, false );
    _domElement.removeEventListener( 'mouseleave', onDocumentMouseCancel, false );
    _domElement.removeEventListener( 'touchmove', onDocumentTouchMove, false );
    _domElement.removeEventListener( 'touchstart', onDocumentTouchStart, false );
    _domElement.removeEventListener( 'touchend', onDocumentTouchEnd, false );

  }

  function dispose() {

    deactivate();

  }

  /**
   * see https://developer.mozilla.org/en-US/docs/Web/Events/mousemove
   */
  function onDocumentMouseMove( event ) {
    event.preventDefault();

    // coordinates returned by getBoundingClientRect as well as clientX and clientY
    // are relative to the viewport, i.e. not including any offset for scrolling of the page
    // see https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect
    // and https://developer.mozilla.org/en-US/docs/Web/API/Touch
    var rect = _domElement.getBoundingClientRect();

    _mouse.x = ( ( event.clientX - rect.left ) / rect.width ) * 2 - 1;
    _mouse.y = - ( ( event.clientY - rect.top ) / rect.height ) * 2 + 1;

    // handle object which is currently being dragged
    if ( _selected && scope.enabled ) {

      let position;

      _raycaster.setFromCamera( _mouse, _camera );

      if ( _raycaster.ray.intersectPlane( _plane, _intersection ) ) {
        position = _intersection.sub( _offset );
      }

      scope.dispatchEvent( { type: 'drag', object: _selected, position: position, event: event } );

    }

    // below here: check for hovered object
    _raycaster.setFromCamera( _mouse, _camera );

    // intersection check for all objects except the currently dragged one
    let index = _objects.indexOf(_selected);
    
    var intersects = _raycaster.intersectObjects( 
      _objects.filter(function(value, arrIndex) {
        return index !== arrIndex;
      }).filter(function(value, arrIndex) {
        if(!_selected) return true;
        return value.parent.interactionGroup !== _selected.parent.interactionGroup;
      })
    );


    if ( intersects.length > 0 ) {

      let object = intersects[0].object;
      let point = intersects[0].point;

      // check whether the hovered object changed or a new object is being hovered
      if ( _hovered !== object ) {

        if ( _hovered !== null ) {
          // in this case the hovered object changed, there we send a 'hoveroff' event
          // for the previously hovered object before sending 'hoveron' for the newly hovered object
          scope.dispatchEvent( { type: 'hoveroff', object: _hovered, event: event } );
        }

        scope.dispatchEvent( { type: 'hoveron', object: object, point: point, event: event } );

        _domElement.style.cursor = 'pointer';
        _hovered = object;

      } else {

        scope.dispatchEvent( { type: 'hover', object: object, point: point, event: event } );

      }

    } else {
      // no more object is being hovered

      if ( _hovered !== null ) {

        scope.dispatchEvent( { type: 'hoveroff', object: _hovered, event: event } );

        _domElement.style.cursor = 'auto';
        _hovered = null;

      }

    }
  }

  /**
   * see https://developer.mozilla.org/en-US/docs/Web/Events/mousedown
   */
  function onDocumentMouseDown( event ) {

    event.preventDefault();

    // we are interested in left clicks only
    if (event.button !== 0)
      return;

    // the following event is used to let the InteractionHandler know whether
    // the left mousebutton is currently down
    scope.dispatchEvent( { type: 'mousedowncustom', event: event } );

    // check for dragged object
    _raycaster.setFromCamera( _mouse, _camera );

    var intersects = _raycaster.intersectObjects( _objects );

    if ( intersects.length > 0 ) {

      _selected = intersects[ 0 ].object;
      let point = intersects[ 0 ].point;

      if (_selected.hasOwnProperty('interactionPlaneNormal')){
        _plane.setFromNormalAndCoplanarPoint( _selected.interactionPlaneNormal , _selected.position );
      }
      else {
        _plane.setFromNormalAndCoplanarPoint( _camera.getWorldDirection( _plane.normal ), _selected.position );
      }

      if ( _raycaster.ray.intersectPlane( _plane, _intersection ) ) {

        _offset.copy( _intersection ).sub( _selected.position );

      }

      _domElement.style.cursor = 'move';

      scope.dispatchEvent( { type: 'dragstart', object: _selected, event: event, point: point } );

    } else {

      // empty click - event is typically used for deselecting selected objects
      scope.dispatchEvent( { type: 'emptyclick', event: event } );

    }
  }

  /**
   * see https://developer.mozilla.org/en-US/docs/Web/Events/mouseup
   * see https://developer.mozilla.org/en-US/docs/Web/Events/mouseleave
   */
  function onDocumentMouseCancel( event ) {

    event.preventDefault();

    // the following event is used to let the InteractionHandler know whether
    // the left mousebutton is currently down
    scope.dispatchEvent( { type: 'mouseupcustom', event: event } );

    // stop dragging
    if ( _selected ) {

      scope.dispatchEvent( { type: 'dragend', object: _selected, event: event } );

      _selected = null;

    }

    _domElement.style.cursor = 'auto';
  }

  /**
   * see https://developer.mozilla.org/en-US/docs/Web/Events/touchmove
   */
  function onDocumentTouchMove( event ) {
    // are we currently interested in a touch?
    if (_touchId === null)
      return;

    //console.log("TOUCH MOVE DRAG CONTROLS");
    if(event.passive === false) event.preventDefault();

    // we ignore touchmove events which don't involve the touch we are interested in
    // see https://developer.mozilla.org/en-US/docs/Web/Events/touchmove
    let _event = null,
        _e;
    for ( let i = 0; i < event.changedTouches.length; i++ ) {
      _e = event.changedTouches[i];
      if ( _e.identifier === _touchId ) {
        _event = _e;
        break;
      }
    }

    if (!_event)
      return;

    event = _event;

    // coordinates returned by getBoundingClientRect as well as clientX and clientY
    // are relative to the viewport, i.e. not including any offset for scrolling of the page
    // see https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect
    // and https://developer.mozilla.org/en-US/docs/Web/API/Touch
    var rect = _domElement.getBoundingClientRect();

    _mouse.x = ( ( event.clientX - rect.left ) / rect.width ) * 2 - 1;
    _mouse.y = - ( ( event.clientY - rect.top ) / rect.height ) * 2 + 1;

    // check for dragged object
    if ( _selected && scope.enabled ) {

      let position;

      _raycaster.setFromCamera( _mouse, _camera );

      if ( _raycaster.ray.intersectPlane( _plane, _intersection ) ) {
        position = _intersection.sub( _offset );
      }

      scope.dispatchEvent( { type: 'drag', object: _selected, position: position, event: event } );

    }

    // below here: check for currently hovered object
    _raycaster.setFromCamera( _mouse, _camera );

    // intersection check for all objects except the currently dragged one
    let index = _objects.indexOf(_selected);
    var intersects = _raycaster.intersectObjects( 
      _objects.filter(function(value, arrIndex) {
        return index !== arrIndex;
      }).filter(function(value, arrIndex) {
        if(!_selected) return true;
        return value.parent.interactionGroup !== _selected.parent.interactionGroup;
      })
    );

    if ( intersects.length > 0 ) {

      let object = intersects[0].object;
      let point = intersects[0].point;

      if (object.hasOwnProperty('interactionPlaneNormal')){
        _plane.setFromNormalAndCoplanarPoint( object.interactionPlaneNormal , object.position );
      }
      else {
        _plane.setFromNormalAndCoplanarPoint( _camera.getWorldDirection( _plane.normal ), object.position );
      }

      if ( _hovered !== object ) {

        if ( _hovered !== null ) {
          // in this case the hovered object changed, there we send a 'hoveroff' event
          // for the previously hovered object before sending 'hoveron' for the newly hovered object
          scope.dispatchEvent( { type: 'hoveroff', object: _hovered, event: event } );
        }

        scope.dispatchEvent( { type: 'hoveron', object: object, point: point, event: event } );

        _hovered = object;

      } else {

        scope.dispatchEvent( { type: 'hover', object: object, point: point, event: event } );

      }

    } else {

      if ( _hovered !== null ) {

        scope.dispatchEvent( { type: 'hoveroff', object: _hovered, event: event } );

        _hovered = null;

      }

    }

  }

  /**
   * see https://developer.mozilla.org/en-US/docs/Web/Events/touchstart
   */
  function onDocumentTouchStart( event ) {
    event.preventDefault();

    // we ignore touchstart events which involve more than one touch
    // see https://developer.mozilla.org/en-US/docs/Web/Events/touchstart
    if ( event.touches.length > 1 )
      return;

    // there must be exactly one changed touch
    event = event.changedTouches[ 0 ];

    // remember the touch identifier
    _touchId = event.identifier;

    // coordinates returned by getBoundingClientRect as well as clientX and clientY
    // are relative to the viewport, i.e. not including any offset for scrolling of the page
    // see https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect
    // and https://developer.mozilla.org/en-US/docs/Web/API/Touch
    var rect = _domElement.getBoundingClientRect();

    _mouse.x = ( ( event.clientX - rect.left ) / rect.width ) * 2 - 1;
    _mouse.y = - ( ( event.clientY - rect.top ) / rect.height ) * 2 + 1;

    _raycaster.setFromCamera( _mouse, _camera );

    var intersects = _raycaster.intersectObjects( _objects );

    if ( intersects.length > 0 ) {

      _selected = intersects[ 0 ].object;
      let point = intersects[ 0 ].point;

      if (_selected.hasOwnProperty('interactionPlaneNormal')){
        _plane.setFromNormalAndCoplanarPoint( _selected.interactionPlaneNormal , _selected.position );
      }
      else {
        _plane.setFromNormalAndCoplanarPoint( _camera.getWorldDirection( _plane.normal ), _selected.position );
      }

      if ( _raycaster.ray.intersectPlane( _plane, _intersection ) ) {

        _offset.copy( _intersection ).sub( _selected.position );

      }

      _domElement.style.cursor = 'move';

      scope.dispatchEvent( { type: 'dragstart', object: _selected, event: event, point: point } );

    } else {

      scope.dispatchEvent( { type: 'emptyclick', event: event } );

    }

  }

  /**
   * see https://developer.mozilla.org/en-US/docs/Web/Events/touchend
   */
  function onDocumentTouchEnd( event ) {
    // are we currently interested in a touch?
    if (_touchId === null)
      return;

    //console.log("TOUCH END DRAG CONTROLS");
    event.preventDefault();

    // we ignore touchend events which don't involve the touch we are interested in
    // see https://developer.mozilla.org/en-US/docs/Web/Events/touchend
    let _event = null,
        _e;
    for ( let i = 0; i < event.changedTouches.length; i++ ) {
      _e = event.changedTouches[i];
      if ( _e.identifier === _touchId ) {
        _event = _e;
        break;
      }
    }

    if (!_event)
      return;

    event = _event;
    _touchId = null;

    // stop dragging
    if ( _selected ) {

      scope.dispatchEvent( { type: 'dragend', object: _selected, event: event } );

      _selected = null;

    }

    if ( _hovered !== null ) {

      scope.dispatchEvent( { type: 'hoveroff', object: _hovered, event: event } );

      _hovered = null;

    }

    _domElement.style.cursor = 'auto';

  }

  activate();

  // API

  this.enabled = true;

  this.activate = activate;
  this.deactivate = deactivate;
  this.dispose = dispose;

  /**
   * This is a fake mouse start functions that simulates the start of a drag event.
   * Note that this was only implemented to support dragging that starts from the outside.
   * This function is then called immediatly when the mouse enters the canvas for the first time.
   * @param {Object} fakeEvent - the fake event object
   * @param {THREE.Mesh} fakeEvent.selected - a mesh of the object that should be dragged
   * @param {THREE.Vector3} fakeEvent.point - the current position of the object
   * @param {THREE.Vector3} fakeEvent.event.clientX - the current clientX
   * @param {THREE.Vector3} fakeEvent.event.clientX - the current clientY
   */
  this.onDocumentMouseDownFake = function ( fakeEvent ) {

    // the following event is used to let the InteractionHandler know whether
    // the left mousebutton is currently down
    scope.dispatchEvent({ type: 'mousedowncustom', event: fakeEvent });

    _selected = fakeEvent.selected;
    let point = fakeEvent.point;

    if (_selected.hasOwnProperty('interactionPlaneNormal')) {
      _plane.setFromNormalAndCoplanarPoint(_selected.interactionPlaneNormal, _selected.position);
    } else {
      _plane.setFromNormalAndCoplanarPoint(_camera.getWorldDirection(_plane.normal), _selected.position);
    }

    _offset = new THREE.Vector3();

    _domElement.style.cursor = 'move';
    scope.dispatchEvent({ type: 'dragstart', object: _selected, event: fakeEvent, point: point });
  }

  /**
   * This is a fake touch start functions that simulates the start of a drag event.
   * Note that this was only implemented to support dragging that starts from the outside.
   * This function is then called immediatly when the mouse enters the canvas for the first time.
   * @param {Object} fakeEvent - the fake event object
   * @param {Object} fakeEvent.changedTouch - the changedTouches[0] of the original event
   * @param {THREE.Mesh} fakeEvent.selected - a mesh of the object that should be dragged
   * @param {THREE.Vector3} fakeEvent.point - the current position of the object
   * @param {THREE.Vector3} fakeEvent.event.clientX - the current clientX
   * @param {THREE.Vector3} fakeEvent.event.clientX - the current clientY
   */
  this.onDocumentTouchStartFake = function (fakeEvent) {

    // there must be exactly one changed touch
    event = fakeEvent.changedTouch;

    // remember the touch identifier
    _touchId = event.identifier;

    // coordinates returned by getBoundingClientRect as well as clientX and clientY
    // are relative to the viewport, i.e. not including any offset for scrolling of the page
    // see https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect
    // and https://developer.mozilla.org/en-US/docs/Web/API/Touch
    var rect = _domElement.getBoundingClientRect();

    _mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    _mouse.y = - ((event.clientY - rect.top) / rect.height) * 2 + 1;

    _selected = fakeEvent.selected;
    let point = fakeEvent.point;

    if (_selected.hasOwnProperty('interactionPlaneNormal')) {
      _plane.setFromNormalAndCoplanarPoint(_selected.interactionPlaneNormal, _selected.position);
    } else {
      _plane.setFromNormalAndCoplanarPoint(_camera.getWorldDirection(_plane.normal), _selected.position);
    }

    _domElement.style.cursor = 'move';
    scope.dispatchEvent({ type: 'dragstart', object: _selected, event: event, point: point });
  }

  // making these callbacks available for the fake drag function
  this.onDocumentMouseMove = onDocumentMouseMove;
  this.onDocumentTouchMove = onDocumentTouchMove;

  // update the camera
  this.updateCamera = function (camera) {
    _camera = camera;
  }

  // Backward compatibility

  this.setObjects = function (objects) {
    _objects = objects;
    //console.error( 'THREE.DragControls: setObjects() has been removed.' );
  };

  this.on = function ( type, listener ) {

    console.warn( 'THREE.DragControls: on() has been deprecated. Use addEventListener() instead.' );
    scope.addEventListener( type, listener );

  };

  this.off = function ( type, listener ) {

    console.warn( 'THREE.DragControls: off() has been deprecated. Use removeEventListener() instead.' );
    scope.removeEventListener( type, listener );

  };

  this.notify = function ( type ) {

    console.error( 'THREE.DragControls: notify() has been deprecated. Use dispatchEvent() instead.' );
    scope.dispatchEvent( { type: type } );

  };

};

THREE.DragControls.prototype = Object.create( THREE.EventDispatcher.prototype );
THREE.DragControls.prototype.constructor = THREE.DragControls;
