/**
 * @file The default threeDManager. Handles all functionality related to the default viewport.
 *
 * @module ThreeDManagerDefault
 * @author Michael Oppitz
 */

let ThreeDManager = function (viewerApi, ___settings) {
  const THREE = require('../../externals/three'),
        TWEEN = require('@tweenjs/tween.js'),
        GLOBAL_UTILS = require('../../shared/util/GlobalUtils'),
        Settings = require('shapedivernodemodule-viewersettings').Settings_2_0,
        ThreeDManagerInterface = require('../interfaces/ThreeDManagerInterface'),
        THREE_D_MANAGER_CONSTANTS = require('./ThreeDManagerConstants'),
        PATH_UTILS = ___settings.pathUtils,
        START_UP_ID = 'startUpId',
        _settings = ___settings.settings,
        _scene = ___settings.scene,
        _geometryNode = ___settings.geometryNode,
        _container = ___settings.container,
        _interactionGroupManager = ___settings.interactionGroupManager,        
        _viewportManager = ___settings.viewportManager,
        _handlers = {},
        _exchangeMeshMaterialObjects = [];

  require('./materials/Reflector');

  let that, _helpers,
      _grid, _groundPlane, _groundPlaneReflection,
      _duration = 0,
      _width = _container.offsetWidth,
      _height = _container.offsetHeight,
      _preFullscreenStyle = {},
      _active = true;

  ////////////
  ////////////
  //
  // the hooks for all settings
  //
  ////////////
  ////////////

  let _showHook = function (v) {
    if (!GLOBAL_UTILS.typeCheck(v, 'boolean'))
      return false;

    if (_container)
      _container.style.opacity = v ? 1 : 0;

    return true;
  };

  let _showSceneTransitionHook = function (v) {
    if (!GLOBAL_UTILS.typeCheck(v, 'string'))
      return false;

    if (_container) {
      if (v.length > 0) {
        _container.style.transition = 'opacity ' + v;
      } else {
        _container.style.transition = 'none';
      }
    }
    return true;
  };


  /**
   * Toggles the full screen mode.
   *
   * @param {Boolean} toggle To what to set the mode
   * @returns {Boolean} If the mode was successfully set
   */
  let _fullscreenHook = function (toggle) {
    if (!GLOBAL_UTILS.typeCheck(toggle, 'boolean', that.warn, 'ThreeDManager.Hook->fullscreen')) return false;

    let element = _container;
    if (_container.getAttribute('sdv-fullscreen') != 'true') {
      let parent = _container.parentElement;
      let foundFullScreenAttr = false;
      while (!foundFullScreenAttr && parent !== null) {

        if (parent.getAttribute('sdv-fullscreen') == 'true') {
          foundFullScreenAttr = true;
          element = parent;
        }
        else {
          parent = parent.parentElement;
        }
      }
    }

    if (toggle && !that._isInFullScreen()) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      }

      // save container style before changing it
      _preFullscreenStyle.left = element.style.left;
      _preFullscreenStyle.top = element.style.top;
      _preFullscreenStyle.backgroundColor = element.style.backgroundColor;
      element.style.left = '0%';
      element.style.top = '0%';
      // browser by default changes background color to black. Use element background color or default white.
      element.style.backgroundColor = element.style.backgroundColor || 'rgba(255,255,255,1)';

      _handlers.renderingHandler.render();
      return true;
    }

    if (!toggle && that._isInFullScreen()) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      }

      // restore container style
      if (GLOBAL_UTILS.typeCheck(_preFullscreenStyle.left, 'string')) {
        element.style.left = _preFullscreenStyle.left;
        delete _preFullscreenStyle.left;
      }
      if (GLOBAL_UTILS.typeCheck(_preFullscreenStyle.top, 'string')) {
        element.style.top = _preFullscreenStyle.top;
        delete _preFullscreenStyle.top;
      }
      // additional check only if default background was set previously. This is to avoid flicker effect.
      if (GLOBAL_UTILS.typeCheck(_preFullscreenStyle.backgroundColor, 'string') && _preFullscreenStyle.backgroundColor !== '') {
        element.style.backgroundColor = _preFullscreenStyle.backgroundColor;
        delete _preFullscreenStyle.backgroundColor;
      }

      _handlers.renderingHandler.render();
      return true;
    }

    // rare case where the fullscreen was enabled/disabled at another place
    // and the setting had to be adjusted accordingly
    if (that._isInFullScreen() === toggle)
      return true;

    return false;
  };

  /**
   * Toggles the groundPlane visibility mode.
   *
   * @param {Boolean} toggle To what to set the mode
   * @returns {Boolean} If the mode was successfully set
   */
  let _groundPlaneVisibilityHook = function (toggle) {
    if (!GLOBAL_UTILS.typeCheck(toggle, 'boolean', that.warn, 'ThreeDManager.Hook->groundPlaneVisibility')) return false;

    if (_groundPlane) _groundPlane.visible = toggle;
    _handlers.renderingHandler.render();
    return true;
  };

  /**
   * Toggles the groundPlane reflection mode.
   *
   * @param {Boolean} toggle To what to set the mode
   * @returns {Boolean} If the mode was successfully set
   */
  let _groundPlaneReflectionVisibilityHook = function (toggle) {
    let scope = 'ThreeDManager.Hook->groundPlaneReflectionVisibility';
    if (!GLOBAL_UTILS.typeCheck(toggle, 'boolean', that.warn, scope)) return false;

    if (_groundPlaneReflection) _groundPlaneReflection.visible = toggle;
    // Has to be deisabled for now
    if (toggle) {
      that.warn(scope, 'The ground plane reflection and the environment as background do not work together right now.');
      that.updateSetting('material.environmentMapAsBackground', false);
      _handlers.materialHandler.setSceneBackground(null);
    }

    _handlers.renderingHandler.render();
    return true;
  };

  /**
   * Sets the threshold for the groundPlane reflectivity mode.
   * @param {Number} t The new threshold
   */
  let _groundPlaneReflectionThresholdHook = function (t) {
    if (!GLOBAL_UTILS.typeCheck(t, 'number', that.warn, 'ThreeDManager.Hook->groundPlaneReflectionThreshold')) return false;

    if (_groundPlaneReflection) _groundPlaneReflection.threshold = t;
    _handlers.renderingHandler.render();
    return true;
  };

  /**
   * Toggles the grid visibility mode.
   *
   * @param {Boolean} toggle To what to set the mode
   * @returns {Boolean} If the mode was successfully set
   */
  let _gridVisibilityHook = function (toggle) {
    if (!GLOBAL_UTILS.typeCheck(toggle, 'boolean', that.warn, 'ThreeDManager.Hook->gridVisibility')) return false;

    if (_grid) _grid.visible = toggle;
    _handlers.renderingHandler.render();
    return true;
  };

  /**
  * Sets the fade in / fade out animation.
  * @param {Number} t The new threshold
  * @return {Boolean} If duration was successfully set
  */
  let _durationHook = function (t) {
    if (!GLOBAL_UTILS.typeCheck(t, 'number', that.warn, 'ThreeDManager.Hook->duration')) return false;

    _duration = t;
    return true;
  };

  /**
   * @extends module:ThreeDManagerInterface~ThreeDManagerInterface
   * @lends module:ThreeDManagerDefault~ThreeDManager
   */
  class ThreeDManager extends ThreeDManagerInterface {

    /**
     * Constructor of the 3D Manager
     */
    constructor(viewerApi, ___settings) {
      super(___settings, GLOBAL_UTILS);

      that = this;
      that.constants = THREE_D_MANAGER_CONSTANTS;
      _handlers.threeDManager = that;

      that.viewerApi = viewerApi;

      _helpers = new (require('./helpers/ThreeDManagerHelpers'))({
        scene: _scene,
        geometryNode: _geometryNode,
        pathUtils: PATH_UTILS,
      }, _handlers);
      _handlers.threeDManager.helpers = _helpers;

      // make sure the container does not have any padding,
      // as this causes the RenderingHandler to enter a vicious cycle of increasing the canvas size
      _container.style.padding = '0';

      ////////////
      ////////////
      //
      // Settings
      //
      ////////////
      ////////////

      let newSettings = new Settings().toJSON().viewer.scene;
      newSettings.camera = Object.assign(THREE_D_MANAGER_CONSTANTS.cameraLegacyDefaultSettings.camera, newSettings.camera);
      require('../../shared/mixins/SettingsMixin').call(that, _settings, newSettings, ___settings.runtimeId);

      ////////////
      ////////////
      //
      // Fullscreen Event Listener
      //
      ////////////
      ////////////

      document.addEventListener('webkitfullscreenchange', function () {
        that.updateSetting('fullscreen', that._isInFullScreen());
      }, false);

      document.addEventListener('mozfullscreenchange', function () {
        that.updateSetting('fullscreen', that._isInFullScreen());
      }, false);

      document.addEventListener('fullscreenchange', function () {
        that.updateSetting('fullscreen', that._isInFullScreen());
      }, false);

      ////////////
      ////////////
      //
      // Rendering Handler
      //
      ////////////
      ////////////

      this.renderingHandler = new (require('./handlers/RenderingHandler'))({
        settings: that.getSection('render'),
        scene: _scene,
        geometryNode: _geometryNode,
        container: _container,
        pathUtils: PATH_UTILS
      }, _handlers);
      _handlers.renderingHandler = this.renderingHandler;

      // error in the handler creation, abort
      if (that.success === false) return;

      ////////////
      ////////////
      //
      // Material Handler
      //
      ////////////
      ////////////
      this.materialHandler = new (require('./handlers/MaterialHandler').MaterialHandler)({
        settings: that.getSection('material'),
        scene: _scene,
        geometryNode: _geometryNode,
        pathUtils: PATH_UTILS,
      }, _handlers);
      _handlers.materialHandler = this.materialHandler;

      // error in the handler creation, abort
      if (that.success === false) return;

      ////////////
      ////////////
      //
      // Camera Handler
      //
      ////////////
      ////////////
      this.cameraHandler = new (require('./handlers/camera/ProxyCameraHandler').ProxyCameraHandler)({
        settings: that.getSection('camera'),
        scene: _scene,
        geometryNode: _geometryNode,
        container: _container
      }, _handlers);
      _handlers.cameraHandler = this.cameraHandler;

      // error in the handler creation, abort
      if (that.success === false) return;

      ////////////
      ////////////
      //
      // Light Handler
      //
      ////////////
      ////////////

      this.lightHandler = new (require('./handlers/LightHandler'))({
        settings: that.getSection('lights'),
        shadows: that.getSettingShallow('render.shadows'),
        scene: _scene,
        geometryNode: _geometryNode,
      }, _handlers);
      _handlers.lightHandler = this.lightHandler;

      // error in the handler creation, abort
      if (that.success === false) return;

      ////////////
      ////////////
      //
      // Interaction Group Handler
      //
      ////////////
      ////////////

      _handlers.interactionGroupManager = _interactionGroupManager;
      _handlers.threeDManager.interactionGroupManager = _interactionGroupManager;

      ////////////
      ////////////
      //
      // Interaction Handler
      //
      ////////////
      ////////////

      this.interactionHandler = new (require('./handlers/InteractionHandler'))({
        settings: that.getSection('interaction'),
        scene: _scene,
        geometryNode: _geometryNode,
        pathUtils: PATH_UTILS,
      }, _handlers);
      _handlers.interactionHandler = this.interactionHandler;

      // error in the handler creation, abort
      if (that.success === false) return;

      ////////////
      ////////////
      //
      // Start-Up Methods
      //
      ////////////
      ////////////

      this.registerHook('show', _showHook);
      this.registerHook('showSceneTransition', _showSceneTransitionHook);
      this.registerHook('fullscreen', _fullscreenHook);
      this.registerHook('groundPlaneVisibility', _groundPlaneVisibilityHook);
      this.registerHook('groundPlaneReflectionVisibility', _groundPlaneReflectionVisibilityHook);
      this.registerHook('groundPlaneReflectionThreshold', _groundPlaneReflectionThresholdHook);
      this.registerHook('gridVisibility', _gridVisibilityHook);
      this.registerHook('duration', _durationHook);
    }

    _isInFullScreen() {
      return !!(document.fullscreenElement || document.webkitFullscreenElement || document.msFullscreenElement || document.mozFullScreenElement);
    }

    _getExchangeMeshMaterialObjects() {
      return _exchangeMeshMaterialObjects;
    }

    ////////////
    ////////////
    //
    // ThreeDManager API
    //
    ////////////
    ////////////

    /** @inheritdoc */
    init(bb) {
      _handlers.renderingHandler.start();

      let sceneExtents = bb.min.distanceTo(bb.max);

      let divisions = 0.1;
      let gridExtents = sceneExtents;
      if (sceneExtents > 1) {
        let tmp = parseInt(sceneExtents);
        tmp = tmp.toString();
        tmp = tmp.length;
        tmp = Math.pow(10, tmp - 1);
        gridExtents = Math.max(Math.ceil(sceneExtents / tmp) * tmp, 1);
        tmp = tmp / 10;
        divisions = gridExtents / tmp;
      }
      else {
        let zeros = 1 - Math.floor(Math.log(sceneExtents) / Math.log(10)) - 2;
        let r = sceneExtents.toFixed(zeros + 1);
        let firstDigit = parseInt(r.substr(r.length - 1)) + 1;
        gridExtents = '0.';
        for (let i = 0; i < zeros; ++i)
          gridExtents = gridExtents + '0';
        gridExtents = parseFloat(gridExtents + firstDigit);
        divisions = firstDigit * 10;
      }

      // grid
      _grid = new THREE.GridHelper(2 * gridExtents, divisions);
      _grid.material.opacity = 0.15;
      _grid.material.transparent = true;
      _grid.isSelectable = false;
      _grid.rotateX(Math.PI / 2);
      _grid.visible = that.getSetting('gridVisibility');

      let obj3Dgrid = new THREE.Object3D();
      obj3Dgrid.SDLocalPath = 'grid';
      obj3Dgrid.add(_grid);

      // ground plane
      let groundPlaneGeom = new THREE.PlaneGeometry(2 * gridExtents, 2 * gridExtents, 2, 2);
      let groundPlaneMaterialProperties = { color: 0xD3D3D3, side: THREE.FrontSide, roughness: 1.0, metalness: 0.0 };
      _groundPlane = new THREE.Mesh(groundPlaneGeom, _handlers.materialHandler.getMaterial(groundPlaneMaterialProperties));
      _groundPlane.receiveShadow = true;
      _groundPlane.visible = that.getSetting('groundPlaneVisibility');
      let obj3Dgroundplane = new THREE.Object3D();
      obj3Dgroundplane.SDLocalPath = 'groundplane';
      obj3Dgroundplane.add(_groundPlane);
      that.addMesh(_groundPlane, _groundPlane.material, groundPlaneMaterialProperties);

      _groundPlaneReflection = new THREE.Reflector(groundPlaneGeom, {
        clipBias: 0.003,
        textureWidth: _width * window.devicePixelRatio,
        textureHeight: _height * window.devicePixelRatio,
        color: 0x777777,
        recursion: 1
      });
      _groundPlaneReflection.receiveShadow = true;
      _groundPlaneReflection.transparent = true;
      _groundPlaneReflection.visible = that.getSetting('groundPlaneReflectionVisibility');
      if (_groundPlaneReflection.visible)
        that.updateSetting('material.environmentMapAsBackground', false);
      obj3Dgroundplane.add(_groundPlaneReflection);

      // adjust position
      let eps = 0.005;
      let bs = bb.getBoundingSphere();
      _grid.position.set(bs.center.x, bs.center.y, bb.min.z - eps);
      _groundPlane.position.set(bs.center.x, bs.center.y, bb.min.z - eps);
      _groundPlaneReflection.scale.set(bs.radius, bs.radius, bs.radius);
      _groundPlaneReflection.position.set(bs.center.x, bs.center.y, bb.min.z - eps);

      _helpers.addSceneObject(obj3Dgrid);
      _helpers.addSceneObject(obj3Dgroundplane);

      _helpers.initialize();
      that.adjustScene();
    }

    /** @inheritdoc */
    adjustScene() {
      if (!_helpers.isInitialized()) return;

      let bb = _geometryNode.computeSceneBoundingBox();
      let bs = new THREE.Sphere();
      bb.getBoundingSphere(bs);

      let eps = 0.005;
      _grid.position.set(_grid.position.x, _grid.position.y, bb.min.z - eps);
      _groundPlane.position.set(_groundPlane.position.x, _groundPlane.position.y, bb.min.z - eps);
      _groundPlaneReflection.position.set(_groundPlaneReflection.position.x, _groundPlaneReflection.position.y, bb.min.z - eps);

      _handlers.materialHandler.adjustToBoundingSphere(bs);
      _handlers.lightHandler.adjustToBoundingSphere(bs);
      _handlers.cameraHandler.adjustToBoundingSphere(bs);

      _handlers.materialHandler.compile();
      _handlers.renderingHandler.updateShadowMap();
      _handlers.renderingHandler.render();
    }

    /** @inheritdoc */
    computeBoundingBox(scenePaths) {
      return _geometryNode.computeBoundingBox(scenePaths);
    }




    /** @inheritdoc */
    pause() {
      _active = false;
      _handlers.renderingHandler.pause();
      _handlers.cameraHandler.pause();
      _handlers.lightHandler.pause();
      _handlers.materialHandler.pause();
    }

    /** @inheritdoc */
    resume() {
      _active = true;
      _handlers.renderingHandler.resume();
      _handlers.cameraHandler.resume();
      _handlers.lightHandler.resume();
      _handlers.materialHandler.resume();
      _handlers.renderingHandler.render();
    }

    /** @inheritdoc */
    destroy() {
      delete _handlers.threeDManager;
      _helpers.destroyViewport();

      // call handler destroy methods
      if (_handlers.renderingHandler)
        _handlers.renderingHandler.destroy();

      if (_handlers.cameraHandler)
        _handlers.cameraHandler.destroy();

      if (_handlers.lightHandler)
        _handlers.lightHandler.destroy();

      if (_handlers.materialHandler)
        _handlers.materialHandler.destroy();

      // de-register hooks and notifiers
      that.deregisterAllHooks();
      that.deregisterAllNotifiers();

      while (_container.firstChild)
        _container.removeChild(_container.firstChild);

      // remove handlers
      for (let key in _handlers)
        delete _handlers[key];
    }

    /** @inheritdoc */
    reload() {
      return _viewportManager.reloadThreeDManager(that.runtimeId);
    }




    /** @inheritdoc */
    addMesh(mesh, material, properties) {
      _helpers.addMesh(mesh, material, properties);
    }

    /** @inheritdoc */
    removeMesh(mesh) {
      for (let i = 0, len = _exchangeMeshMaterialObjects.length; i < len; i++) {
        if (mesh === _exchangeMeshMaterialObjects[i].mesh) {
          _exchangeMeshMaterialObjects.splice(i, 1);
          break;
        }
      }


      _helpers.removeMesh(mesh);
    }

    /** @inheritdoc */
    addAnchor(mesh, properties) {
      _helpers.addAnchor(mesh, properties);
    }

    /** @inheritdoc */
    removeAnchor(mesh) {
      _helpers.removeAnchor(mesh);
    }




    /** @inheritdoc */
    fadeIn(path, duration) {
      let obj = PATH_UTILS.getPathObject(_geometryNode, path);
      if (obj == null) {
        return Promise.reject(new Error('Could not fade in geometry, path not found'));
      }

      if (!_active)
        duration = 0;
      else if (!GLOBAL_UTILS.typeCheck(duration, 'number'))
        duration = _duration;


      return new Promise(function (resolve) {
        _helpers.toggleMeshes(true);
        obj.traverseVisible(function (o) {
          if (o.SDMaterialDefinition && o.material) {
            o.material.transparent = true;
            o.material.opacity = 0.0;
          }
        });
        _helpers.toggleMeshes(false);

        let fadeInProperties = { opacity: 0 };
        let fadeInTween = new TWEEN.Tween(fadeInProperties)
          .to({
            opacity: 1
          }, duration)
          .onUpdate(function () {
            _helpers.toggleMeshes(true);
            obj.traverseVisible(function (o) {
              if (o.SDMaterialDefinition && o.material) {
                if (o.SDMaterialDefinition.materialType === 'gem') {
                  if (o.material.side === THREE.FrontSide) {
                    let opacityTarget = o.SDMaterialDefinition.transparent ? o.SDMaterialDefinition.opacityFront : 1.0;
                    o.material.opacity = fadeInProperties.opacity * opacityTarget;
                  } else {
                    let opacityTarget = o.SDMaterialDefinition.transparent ? o.SDMaterialDefinition.opacityBack : 1.0;
                    o.material.opacity = fadeInProperties.opacity * opacityTarget;
                  }
                } else {
                  let opacityTarget = o.SDMaterialDefinition.transparent ? o.SDMaterialDefinition.opacity : 1.0;
                  o.material.opacity = fadeInProperties.opacity * opacityTarget;
                }
              }
            });
            _helpers.toggleMeshes(false);
          })
          .onComplete(function () {
            _helpers.toggleMeshes(true);
            obj.traverseVisible(function (o) {
              if (o.SDMaterialDefinition && o.material) {
                if (o.SDMaterialDefinition.materialType === 'gem') {
                  if (o.material.side === THREE.FrontSide) {
                    o.material.opacity = o.SDMaterialDefinition.opacityFront;
                    o.material.transparent = o.SDMaterialDefinition.transparent;
                  } else {
                    o.material.opacity = o.SDMaterialDefinition.opacityBack;
                    o.material.transparent = o.SDMaterialDefinition.transparent;
                  }
                } else {
                  o.material.opacity = o.SDMaterialDefinition.opacity;
                  o.material.transparent = o.SDMaterialDefinition.transparent;
                }

              }
            });
            _helpers.toggleMeshes(false);
            _handlers.renderingHandler.unregisterForContinuousRendering('FADE_IN_' + path);
            resolve();
          });

        _handlers.renderingHandler.registerForContinuousRendering('FADE_IN_' + path);
        fadeInTween.start();
      });
    }

    /** @inheritdoc */
    fadeOut(path, duration) {
      let obj = PATH_UTILS.getPathObject(_geometryNode, path);
      if (obj == null) {
        return Promise.reject(new Error('Could not fade out, geometry, path not found.'));
      }

      if (!_active)
        duration = 0;
      else if (!GLOBAL_UTILS.typeCheck(duration, 'number'))
        duration = _duration;

      return new Promise(function (resolve) {
        _helpers.toggleMeshes(true);
        obj.traverseVisible(function (o) {
          if (o.hasOwnProperty('material')) {
            o.material.transparent = true;
          }
        });
        _helpers.toggleMeshes(false);

        let fadeOutProperties = { opacity: 1 };
        let fadeOutTween = new TWEEN.Tween(fadeOutProperties)
          .to({
            opacity: 0
          }, duration)
          .onUpdate(function () {
            _helpers.toggleMeshes(true);
            obj.traverseVisible(function (o) {
              if (o.SDMaterialDefinition && o.material) {
                if (o.SDMaterialDefinition.materialType === 'gem') {
                  if (o.material.side === THREE.FrontSide) {
                    o.material.opacity = fadeOutProperties.opacity * o.SDMaterialDefinition.opacityFront;
                  } else {
                    o.material.opacity = fadeOutProperties.opacity * o.SDMaterialDefinition.opacityBack;
                  }
                } else {
                  o.material.opacity = fadeOutProperties.opacity * o.SDMaterialDefinition.opacity;
                }
              }
            });
            _helpers.toggleMeshes(false);
          })
          .onComplete(function () {
            _helpers.toggleMeshes(true);
            obj.traverseVisible(function (o) {
              if (o.SDMaterialDefinition && o.material) {
                o.material.opacity = 0;
              }
            });
            _helpers.toggleMeshes(false);
            _handlers.renderingHandler.unregisterForContinuousRendering('FADE_OUT_' + path);
            resolve();
          });

        _handlers.renderingHandler.registerForContinuousRendering('FADE_OUT_' + path);
        fadeOutTween.start();
      });
    }




    /** @inheritdoc */
    setLiveTransformation(group, scenePaths, transformations, reset, duration) {
      return _helpers.setLiveTransformation(group, scenePaths, transformations, reset, duration);
    }




    /** @inheritdoc */
    updateInteractions(path, options) {
      if (!options) return;
      if (GLOBAL_UTILS.typeCheck(options.interactionGroup, 'string')) {

        _handlers.highlightingHandler.setToggleHighlights(false);

        let group = options.interactionGroup;

        let mode = 'sub';
        if (GLOBAL_UTILS.typeCheck(options.interactionMode, 'string')) {
          if (options.interactionMode === 'global') {
            mode = 'global';
          }
        } else if(GLOBAL_UTILS.typeCheck(options.interactionMode, 'notnegative')) {
          mode = options.interactionMode;
        }

        let n = GLOBAL_UTILS.typeCheck(options.dragPlaneNormal, 'vector3any') ? GLOBAL_UTILS.toVector3(options.dragPlaneNormal) : undefined;

        _handlers.interactionHandler.addToInteractionGroup(path, group, mode, n);

        _handlers.highlightingHandler.setToggleHighlights(true);
      }
    }

    /** @inheritdoc */
    removeFromInteractions(path) {
      _handlers.interactionHandler.removeFromInteractions(path);
    }

    /** @inheritdoc */
    toggleGeometry(show, hide) {
      _helpers.toggleGeometry(show, hide);
    }
  }

  return new ThreeDManager(viewerApi, ___settings);
};

module.exports = ThreeDManager;
