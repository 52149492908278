"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var DropdownSettingComponent_1 = require("../DropdownSettingComponent");
var SDControlsSettings_1 = require("../../settings/SDControlsSettings");
var CameraDropdownSettingComponent = /** @class */ (function (_super) {
    __extends(CameraDropdownSettingComponent, _super);
    function CameraDropdownSettingComponent(api, settingDefinition, _parent, _componentFactory, _controlsEvents) {
        var _this = _super.call(this, api, {
            value: CameraDropdownSettingComponent._choices[api.getSetting("camera.cameraTypes.active")],
            choices: CameraDropdownSettingComponent._choices,
            type: "dropdown" /* DROPDOWN */,
            title: settingDefinition.title,
            name: settingDefinition.name,
            setting: "camera.cameraTypes.active",
            order: settingDefinition.order,
            id: settingDefinition.id,
            groupId: settingDefinition.groupId,
        }, _parent, _componentFactory) || this;
        _this._parent = _parent;
        _this._componentFactory = _componentFactory;
        _this._controlsEvents = _controlsEvents;
        _this._perspectiveSettings = [
            "camera.cameraTypes.perspective.fov",
            "camera.controls.orbit.autoRotationSpeed",
            "camera.controls.orbit.damping",
            "camera.controls.orbit.enableKeyPan",
            "camera.controls.orbit.enablePan",
            "camera.controls.orbit.enableRotation",
            "camera.controls.orbit.enableZoom",
            "camera.controls.orbit.keyPanSpeed",
            "camera.controls.orbit.rotationSpeed",
            "camera.controls.orbit.zoomSpeed",
            "camera.controls.orbit.panSpeed"
        ];
        _this._ortographicSettings = [
            "camera.controls.orthographic.damping",
            "camera.controls.orthographic.enableKeyPan",
            "camera.controls.orthographic.enablePan",
            "camera.controls.orthographic.enableZoom",
            "camera.controls.orthographic.zoomSpeed",
            "camera.controls.orthographic.zoomSpeed",
            "camera.controls.orthographic.panSpeed"
        ];
        _this._settingDefinition.update = function (v) {
            var index = CameraDropdownSettingComponent._choices.indexOf(v);
            _this._currentSelection = CameraDropdownSettingComponent._choices[index] === "PERSPECTIVE" ? "perspective" : "ortographic";
            _this._api.updateSettingAsync("camera.cameraTypes.active", index);
            _this.createSettings();
            _this._previousSelection = _this._currentSelection;
        };
        _this._currentSelection = _this._settingDefinition.value === "PERSPECTIVE" ? "perspective" : "ortographic";
        _this.createSettings();
        _this._previousSelection = _this._currentSelection;
        return _this;
    }
    Object.defineProperty(CameraDropdownSettingComponent.prototype, "value", {
        // #endregion Constructors (1)
        get: function () {
            return this._component.value;
        },
        set: function (v) {
            if (typeof v === "number") {
                v = CameraDropdownSettingComponent._choices[v];
            }
            this._component.value = v;
        },
        enumerable: true,
        configurable: true
    });
    CameraDropdownSettingComponent.prototype.createSettings = function () {
        if (this._previousSelection !== this._currentSelection) {
            var perspective = this._currentSelection === "perspective";
            this.destroySettings(perspective ? this._ortographicSettings : this._perspectiveSettings);
            var settings = perspective ? this.createPerspectiveSettings() : this.createOrtographicSettings();
            for (var key in settings) {
                this._controlsEvents.trigger("create_setting" /* CREATE_SETTING */, {
                    key: key,
                    definition: settings[key]
                });
            }
        }
    };
    CameraDropdownSettingComponent.prototype.createPerspectiveSettings = function () {
        var _this = this;
        var definition = this._api.getSettingDefinitions();
        var settings = {
            "camera.cameraTypes.perspective.fov": {
                id: "camera.cameraTypes.perspective.fov",
                setting: "camera.cameraTypes.perspective.fov",
                type: "slider" /* SLIDER */,
                name: "Field of View",
                title: definition["scene.camera.cameraTypes.perspective.fov"].description,
                value: this._api.getSetting("scene.camera.cameraTypes.perspective.fov"),
                groupId: 4,
                order: 2,
                min: 10,
                max: 90,
                step: 1,
            },
            "camera.controls.orbit.enableZoom": {
                value: !this._api.getSetting("camera.controls.orbit.enableZoom"),
                setting: "camera.controls.orbit.enableZoom",
                id: "camera.controls.orbit.enableZoom",
                type: "checkbox" /* CHECKBOX */,
                name: "Disable Zoom",
                title: definition["scene.camera.controls.orbit.enableZoom"].description,
                order: 3,
                groupId: 4,
                update: function (val) { return _this._api.updateSettingAsync("camera.controls.orbit.enableZoom", !val); },
                convertValue: function (val) { return !val; }
            },
            "camera.controls.orbit.zoomSpeed": {
                value: this._api.getSetting("camera.controls.orbit.zoomSpeed"),
                id: "camera.controls.orbit.zoomSpeed",
                order: 4,
                groupId: 4,
                setting: "camera.controls.orbit.zoomSpeed",
                name: "Zoom Speed",
                title: definition['scene.camera.controls.orbit.zoomSpeed'].description,
                type: "slider" /* SLIDER */,
                min: 0.0,
                max: 1 + SDControlsSettings_1.SDControlsSettings.EPS,
                step: 0.01,
            },
            "camera.controls.orbit.enablePan": {
                value: this._api.getSetting("camera.controls.orbit.enablePan"),
                id: "camera.controls.orbit.enablePan",
                order: 5,
                groupId: 4,
                setting: "camera.controls.orbit.enablePan",
                name: "Disable Pan",
                title: definition['scene.camera.controls.orbit.enablePan'].description,
                type: "checkbox" /* CHECKBOX */,
                update: function (val) { return _this._api.updateSettingAsync("camera.controls.orbit.enablePan", !val); },
                convertValue: function (val) { return !val; }
            },
            "camera.controls.orbit.panSpeed": {
                value: this._api.getSetting("camera.controls.orbit.panSpeed"),
                id: "camera.controls.orbit.panSpeed",
                order: 6,
                groupId: 4,
                setting: "camera.controls.orbit.panSpeed",
                name: "Pan Speed",
                title: definition['scene.camera.controls.orbit.panSpeed'].description,
                type: "slider" /* SLIDER */,
                min: 0.0,
                max: 1 + SDControlsSettings_1.SDControlsSettings.EPS,
                step: 0.01,
            },
            "camera.controls.orbit.enableKeyPan": {
                value: this._api.getSetting("camera.controls.orbit.enableKeyPan"),
                id: "camera.controls.orbit.enableKeyPan",
                order: 7,
                groupId: 4,
                setting: "camera.controls.orbit.enableKeyPan",
                name: "Disable Key pan",
                title: definition['scene.camera.controls.orbit.enableKeyPan'].description,
                type: "checkbox" /* CHECKBOX */,
                update: function (val) { return _this._api.updateSettingAsync("camera.controls.orbit.enableKeyPan", !val); },
                convertValue: function (val) { return !val; }
            },
            "camera.controls.orbit.keyPanSpeed": {
                value: this._api.getSetting("camera.controls.orbit.keyPanSpeed"),
                id: "camera.controls.orbit.keyPanSpeed",
                order: 8,
                groupId: 4,
                setting: "camera.controls.orbit.keyPanSpeed",
                name: "Key pan speed",
                title: definition['scene.camera.controls.orbit.keyPanSpeed'].description,
                type: "slider" /* SLIDER */,
                min: 0,
                max: 1,
                step: 0.01
            },
            "camera.controls.orbit.autoRotationSpeed": {
                value: this._api.getSetting("camera.controls.orbit.autoRotationSpeed"),
                id: "camera.controls.orbit.autoRotationSpeed",
                order: 9,
                groupId: 4,
                setting: "camera.controls.orbit.autoRotationSpeed",
                title: definition['scene.camera.controls.orbit.autoRotationSpeed'].description,
                name: "Auto Rotation",
                type: "slider" /* SLIDER */,
                min: -10,
                max: 10 + SDControlsSettings_1.SDControlsSettings.EPS,
                step: 0.01
            },
            "camera.controls.orbit.damping": {
                value: this._api.getSetting("camera.controls.orbit.damping"),
                id: "camera.controls.orbit.damping",
                order: 10,
                groupId: 4,
                setting: "camera.controls.orbit.damping",
                name: "Damping Factor",
                title: definition['scene.camera.controls.orbit.damping'].description,
                type: "slider" /* SLIDER */,
                min: 0,
                max: 1,
                step: 0.01
            },
            "camera.controls.orbit.enableRotation": {
                setting: "camera.controls.orbit.enableRotation",
                value: !this._api.getSetting("camera.controls.orbit.enableRotation"),
                type: "checkbox" /* CHECKBOX */,
                name: "Disable Rotation",
                title: definition['scene.camera.controls.orbit.enableRotation'].description,
                order: 9,
                groupId: 4,
                id: "camera.controls.orbit.enableRotation",
                update: function (val) { return _this._api.updateSettingAsync("camera.controls.orbit.enableRotation", !val); },
                convertValue: function (val) { return !val; }
            },
            "camera.controls.orbit.rotationSpeed": {
                value: this._api.getSetting("camera.controls.orbit.rotationSpeed"),
                id: "camera.controls.orbit.rotationSpeed",
                order: 6,
                groupId: 4,
                setting: "camera.controls.orbit.rotationSpeed",
                name: "Rotation Speed",
                title: definition['scene.camera.controls.orbit.rotationSpeed'].description,
                type: "slider" /* SLIDER */,
                min: 0.0,
                max: 1 + SDControlsSettings_1.SDControlsSettings.EPS,
                step: 0.01
            },
        };
        return settings;
    };
    CameraDropdownSettingComponent.prototype.createOrtographicSettings = function () {
        var _this = this;
        var definition = this._api.getSettingDefinitions();
        var settings = {
            "camera.controls.orthographic.enableZoom": {
                value: !this._api.getSetting("camera.controls.orthographic.enableZoom"),
                setting: "camera.controls.orthographic.enableZoom",
                id: "camera.controls.orthographic.enableZoom",
                type: "checkbox" /* CHECKBOX */,
                name: "Disable Zoom",
                title: definition["scene.camera.controls.orthographic.enableZoom"].description,
                order: 2,
                groupId: 4,
                update: function (val) { return _this._api.updateSettingAsync("camera.controls.orthographic.enableZoom", !val); },
                convertValue: function (val) { return !val; }
            },
            "camera.controls.orthographic.zoomSpeed": {
                value: this._api.getSetting("camera.controls.orthographic.zoomSpeed"),
                id: "camera.controls.orthographic.zoomSpeed",
                order: 3,
                groupId: 4,
                setting: "camera.controls.orthographic.zoomSpeed",
                name: "Zoom Speed",
                title: definition['scene.camera.controls.orthographic.zoomSpeed'].description,
                type: "slider" /* SLIDER */,
                min: 0.0,
                max: 1 + SDControlsSettings_1.SDControlsSettings.EPS,
                step: 0.01,
            },
            "camera.controls.orthographic.enablePan": {
                value: !this._api.getSetting("camera.controls.orthographic.enablePan"),
                id: "camera.controls.orthographic.enablePan",
                order: 4,
                groupId: 4,
                setting: "camera.controls.orthographic.enablePan",
                name: "Disable Pan",
                title: definition['scene.camera.controls.orthographic.enablePan'].description,
                type: "checkbox" /* CHECKBOX */,
                update: function (val) { return _this._api.updateSettingAsync("camera.controls.orthographic.enablePan", !val); },
                convertValue: function (val) { return !val; }
            },
            "camera.controls.orthographic.panSpeed": {
                value: this._api.getSetting("camera.controls.orthographic.panSpeed"),
                id: "camera.controls.orthographic.panSpeed",
                order: 5,
                groupId: 4,
                setting: "camera.controls.orthographic.panSpeed",
                name: "Pan Speed",
                title: definition['scene.camera.controls.orthographic.panSpeed'].description,
                type: "slider" /* SLIDER */,
                min: 0.0,
                max: 1 + SDControlsSettings_1.SDControlsSettings.EPS,
                step: 0.01,
            },
            "camera.controls.orthographic.enableKeyPan": {
                value: !this._api.getSetting("camera.controls.orthographic.enableKeyPan"),
                id: "camera.controls.orthographic.enableKeyPan",
                order: 6,
                groupId: 4,
                setting: "camera.controls.orthographic.enableKeyPan",
                name: "Disable Key pan",
                title: definition['scene.camera.controls.orthographic.enableKeyPan'].description,
                type: "checkbox" /* CHECKBOX */,
                update: function (val) { return _this._api.updateSettingAsync("camera.controls.orthographic.enableKeyPan", !val); },
                convertValue: function (val) { return !val; }
            },
            "camera.controls.orthographic.keyPanSpeed": {
                value: this._api.getSetting("camera.controls.orthographic.keyPanSpeed"),
                id: "camera.controls.orthographic.keyPanSpeed",
                order: 7,
                groupId: 4,
                setting: "camera.controls.orthographic.keyPanSpeed",
                name: "Key pan speed",
                title: definition['scene.camera.controls.orthographic.keyPanSpeed'].description,
                type: "slider" /* SLIDER */,
                min: 0,
                max: 1,
                step: 0.01
            },
            "camera.controls.orthographic.damping": {
                value: this._api.getSetting("camera.controls.orthographic.damping"),
                id: "camera.controls.orthographic.damping",
                order: 8,
                groupId: 4,
                setting: "camera.controls.orthographic.damping",
                name: "Damping Factor",
                title: definition['scene.camera.controls.orthographic.damping'].description,
                type: "slider" /* SLIDER */,
                min: 0,
                max: 1.0000001,
                step: 0.01
            },
        };
        return settings;
    };
    CameraDropdownSettingComponent.prototype.destroySettings = function (ids) {
        var _this = this;
        ids.forEach(function (x) { return _this._controlsEvents.trigger("remove_setting" /* REMOVE_SETTING */, x); });
    };
    // #region Constructors (1)
    CameraDropdownSettingComponent._choices = [
        "PERSPECTIVE",
        "TOP",
        "BOTTOM",
        "RIGHT",
        "LEFT",
        "BACK",
        "FRONT"
    ];
    return CameraDropdownSettingComponent;
}(DropdownSettingComponent_1.DropdownSettingComponent));
exports.CameraDropdownSettingComponent = CameraDropdownSettingComponent;
